import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserInterface } from '../../models/user';
import { TimeInterface } from '../../models/time';
import { Observable } from 'rxjs';
import * as utils from 'src/app/shared/utils/utils';
import { ResponseStadisticsInterface } from '../../models/responseStadistics';

type VerifyData = {
    service_sid: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: UserInterface;

  constructor(private httpClient: HttpClient ) { }



  verify(data) {
      const url = environment.server + '/api/v2/verification/';
      return this.httpClient.post(url, data).toPromise();
  }

  async getTimeServer(): Promise<TimeInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    const data = await this.httpClient.get(environment.server + '/auth/time/', httpOptions).toPromise();
    return data as TimeInterface;
  }

  async logout() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    const data = await this.httpClient.post(environment.server + '/auth/logout/', {}, httpOptions).toPromise();
    return data;
  }

  async login(user): Promise<any> {
    const data = await this.httpClient.post(
      environment.server + '/auth/login/',
      JSON.stringify(user),
    ).toPromise() as any;
    return data;
  }

  public loginBackend(user, token, role) {
    const body = new HttpParams()
      .set('cedulaRuc', user.email)
      .set('password', user.password)
      .set('type', 'web')
      .set('registration_id', token)
      .set('role', role);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.httpClient.post(
      environment.server + '/login/',
      body.toString(),
      httpOptions
    );
  }

  async reloadDataUser(): Promise<UserInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken()
      })
    };
    const user = await this.httpClient.post(
      environment.server + '/auth/account/',
      {},
      httpOptions).toPromise() as UserInterface;
    return user;
  }

  async reloadDataUserGuard(): Promise<UserInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken()
      })
    };
    const user = await this.httpClient.post(
      environment.server + '/auth/account/',
      {},
      httpOptions).toPromise() as UserInterface;
    return user;
  }

  public updateUser(user: UserInterface, idUser: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    return this.httpClient
    .patch(
      environment.server + '/api/v2/users/' + idUser + '/',
      user,
      httpOptions
    )
    .toPromise();
  }

  getUserServer() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    return this.httpClient
      .get(environment.server + '/getData', httpOptions)
      .toPromise();
  }

  updatePassword(beforePassword: string, newPassword: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    const updatePass = {
      previous: beforePassword,
      password: newPassword,
    };
    return this.httpClient.post(
      environment.server + '/auth/change-password/',
      JSON.stringify(updatePass),
      httpOptions
    );
  }

  updateImageProfile(images: any, idUser: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    return this.httpClient
      .patch(
        environment.server +
          '/api/v2/profiles/' + idUser + '/',
        images,
        httpOptions
      )
      .toPromise();
  }

  sendRegisterForm(registro: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken()
      })
    };
    return this.httpClient.post(
      environment.server + '/auth/signup/',
      JSON.stringify(registro),
      httpOptions
    );
  }

  public getPosition(): Observable<Position> {
    // tslint:disable-next-line: deprecation
    return Observable.create((Observer: any) => {
      navigator.geolocation.watchPosition(
        (pos: Position) => {
          Observer.next(pos);
        },
        error => {
        },
        {
          enableHighAccuracy: true
        }
      );
    });
  }

  async getStadisticsUser(id: number): Promise<ResponseStadisticsInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    // tslint:disable-next-line: max-line-length
    const data = await this.httpClient.get(environment.server + '/api/v1/users/' + id + '/stadistics/?role=' + utils.getRole(), httpOptions)
      .toPromise() as ResponseStadisticsInterface;
    return data;
  }

  activateUser(checked: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    const urlAvailable: string = checked ? '/api/v2/partners/available/' : '/api/v2/partners/available/';
    return this.httpClient
      .post( environment.server + urlAvailable,
      '',
        httpOptions
      )
      .toPromise();
  }

  public updateLocationProvider(lat, lng, idUser: number) {
    const body = new HttpParams()
    .set('lat', lat)
    .set('lon', lng);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    return this.httpClient.post(
      environment.server + '/api/v1/users/' + idUser + '/location/',
      body.toString(),
      httpOptions
    );
  }

  async recoveryPassword(username: string) {
    const body = new HttpParams()
      .set('username', username);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return await this.httpClient.post(
      environment.server + '/auth/forgot-password/',
      {username},
      httpOptions
    ).toPromise();
  }

  createFcmToken(token) {
    const body = new HttpParams()
      .set('type', 'web')
      .set('registration_id', token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Token ' + utils.getToken() // + this._userService.token
      })
    };
    return this.httpClient.post(
      environment.server + '/auth/device/',
      body.toString(),
      httpOptions
    );
  }


  public setUser(user: UserInterface): Promise<UserInterface> {
    return new Promise((resolve, reject) => {
      this.user = user;
      resolve(user);
    });
  }

  public getUser(): UserInterface {
    return this.user;
  }

  public async getFavorites(): Promise<UserInterface[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        Authorization: 'Token ' + utils.getToken()
      })
    };
    return await this.httpClient.get(environment.server + '/api/v2/partners/subscriptions/', httpOptions).toPromise() as UserInterface[];
  }

}
