import { UserInterface } from '../models/user';

export function validarCedula(cedula: string): boolean {
  let suma = 0;
  const cedulaArray = cedula.split('');
  if (cedulaArray.length !== 10) {
    return false;
  }
  for (let index = 0; index < cedulaArray.length - 1; index++) {
    if (index % 2 === 0) {
      if ( +cedulaArray[index] * 2 >= 10) {
        suma = suma + +cedulaArray[index] * 2 - 9;
      } else {
        suma = suma + +cedulaArray[index] * 2;
      }
    } else {
      if ( +cedulaArray[index] * 1 >= 10) {
        suma = suma + +cedulaArray[index] * 1 - 9;
      } else {
        suma = suma + +cedulaArray[index] * 1;
      }
    }
  }
  suma = suma % 10 ? 10 - suma % 10 : 0;
  if (+cedulaArray[cedulaArray.length - 1] === suma) {
    return true;
  } else {
    return false;
  }
}

export function validarRUC(ruc: string): boolean {
  return validarRucNatural(ruc) || validarRucExtranjero(ruc) || validarRucJuridicoPublico(ruc);
}

export function validarRucNatural(ruc: string): boolean {
  const rucArray = ruc.split('');
  if (rucArray.length !== 13) {
    return false;
  }
  const rucDigito = rucArray.splice(10, 3);
  if (validarCedula(rucArray.join(''))
      && (+(rucArray[0] + rucArray[1]) >= 1 && +(rucArray[0] + rucArray[1]) <= 22)
      && ( (rucDigito[0] + rucDigito[1] + rucDigito[2]) !== '000' ) ) {
        return true;
  } else {
    return false;
  }
}


// 9
export function validarRucExtranjero(ruc: string) {
  let suma = 0;
  const rucArray = ruc.split('');
  if (rucArray.length !== 13) {
    return false;
  }
  const rucDigito = rucArray.splice(10, 3);
  if ( (+(rucArray[0] + rucArray[1]) >= 1 && +(rucArray[0] + rucArray[1]) <= 22)
       && ( (rucDigito[0] + rucDigito[1] + rucDigito[2] ) !== '000' ) ) {
        suma = (+rucArray[0] * 4) + (+rucArray[1] * 3) + (+rucArray[2] * 2) +
               (+rucArray[3] * 7) + (+rucArray[4] * 6) + (+rucArray[5] * 5) +
               (+rucArray[6] * 4) + (+rucArray[7] * 3) + (+rucArray[8] * 2);
        suma = suma % 11;
        suma = 11 - suma;
        while (suma >= 10) {
          suma = 11 - suma;
        }
        if ( suma === +rucArray[9]) {
          return true;
        } else {
          return false;
        }
  } else {
    return false;
  }
}


// 6
export function validarRucJuridicoPublico(ruc: string) {
  let suma = 0;
  const rucArray = ruc.split('');
  if (rucArray.length !== 13) {
    return false;
  }
  const rucDigito = rucArray.splice(9, 4);
  if ( (+(rucArray[0] + rucArray[1]) >= 1 && +(rucArray[0] + rucArray[1]) <= 22)
       && ( (rucDigito[0] + rucDigito[1] + rucDigito[2] + rucDigito[3]) !== '0000' ) ) {
        suma = (+rucArray[0] * 3) + (+rucArray[1] * 2) +
               (+rucArray[2] * 7) + (+rucArray[3] * 6) + (+rucArray[4] * 5) +
               (+rucArray[5] * 4) + (+rucArray[6] * 3) + (+rucArray[7] * 2);
        suma = suma % 11;
        suma = 11 - suma;
        while (suma >= 10) {
          suma = 11 - suma;
        }
        if ( suma === +rucArray[8]) {
          return true;
        } else {
          return false;
        }
  } else {
    return false;
  }
}


export function setRole(token: string, role: number, typeLogin: number) {
  const tokenNew = token.concat(role + '', typeLogin + '');
  localStorage.setItem('isLoggedin', tokenNew);
}

export function setToken(token: string, user?: UserInterface) {
  localStorage.setItem('isLoggedin', token);
}

export function getRole(): number {
  const token = localStorage.getItem('isLoggedin');
  const role = +token.charAt(token.length - 2);
  return role;
}

export function getToken(): string {
    const token = localStorage.getItem('isLoggedin');
    return token;
}

export function getTypelogin(): number {
  const token = localStorage.getItem('isLoggedin');
  const typeLogin = +token.charAt(token.length - 1);
  return typeLogin;
}

export function getUser(): UserInterface {
  let user = {} as UserInterface;
  user =  JSON.parse(localStorage.getItem('user')) as UserInterface;
  return user;
}


export function getJsLanding() {
  const scripts = [
    '../../assets/landing/js/jquery-3.2.0.min.js',
    '../../assets/landing/js/jquery-ui.js',
    '../../assets/landing/js/bootstrap.min.js',
    '../../assets/landing/js/jquery.slicknav.min.js',
    '../../assets/landing/js/owl.carousel.min.js',
    '../../assets/landing/js/magnific-popup.min.js',
    '../../assets/landing/js/counterup.js',
    '../../assets/landing/js/jquery.waypoints.min.js',
    '../../assets/landing/js/jquery.mb.YTPlayer.min.js',
    '../../assets/landing/js/theme.js',
  ];
  scripts.forEach(s => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = s;
    script.async = false;
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}

export function getJsLogin() {
  const scripts = [
    '../../assets/login/vendor/jquery/jquery-3.2.1.min.js',
    '../../assets/login/vendor/animsition/js/animsition.min.js',
    '../../assets/login/vendor/bootstrap/js/popper.js',
    '../../assets/login/vendor/bootstrap/js/bootstrap.min.js',
    '../../assets/login/vendor/select2/select2.min.js',
    '../../assets/login/vendor/daterangepicker/moment.min.js',
    '../../assets/login/vendor/daterangepicker/daterangepicker.js',
    '../../assets/login/vendor/countdowntime/countdowntime.js',
    '../../assets/login/js/main.js'
  ];
  return new Promise((resolve, reject) => {
    scripts.forEach(s => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = s;
      script.async = false;
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  });

}

export function getJsDashboard() {
  const scripts = [
    '../../assets/dashboard/js/stisla.js',
    '../../assets/dashboard/js/cleave-js/dist/cleave.js',
    '../../assets/dashboard/js/scripts.js',
    '../../assets/dashboard/js/custom.js'
  ];
  scripts.forEach(s => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = s;
    script.async = false;
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}
