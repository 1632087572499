import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { ToastStatus } from './../enums/toast-state.enum';

@Injectable({
  providedIn: 'root'
})
export class ToastClientService {

  private readonly state = ToastStatus;

  constructor(private toastr: ToastrService) {
  }

  showToast(state: number, message: string, tittle?: string, time?: number) {
    switch (state) {
      case this.state.SUCCESS:
        this.toastr.success(tittle, message, { timeOut: time, tapToDismiss: true });
        break;
      case this.state.WARNING:
        this.toastr.warning(tittle, message, { timeOut: time, tapToDismiss: true });
        break;
      case this.state.INFO:
        this.toastr.info(tittle, message, { timeOut: time, tapToDismiss: true });
        break;
      case this.state.ERROR:
        this.toastr.error(tittle, message, { timeOut: time, tapToDismiss: true });
        break;
      default:
        this.toastr.error('Error', 'I am a teapot', { timeOut: 3000, tapToDismiss: true });
        break;
    }
  }

}
