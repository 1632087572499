import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { ToastStatus } from './../shared/enums/toast-state.enum';
import { ToastClientService } from 'src/app/shared/services/toast.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  private readonly state = ToastStatus;
  private username = new FormControl('', Validators.required);
  private rememberAccount: FormGroup;
  loading = false;
  disabled: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private autService: AuthService,
    private toastService: ToastrService,
    private toastClientSrv: ToastClientService) { }

  ngOnInit() {
    this.loading = false;
    this.disabled = false;
    this.RememberFormulario();
    this.loading = true;
  }

  async recuperar() {
    try {
      this.disabled = true;
      if (this.username.errors) {
        if (this.username.errors.required) {
          this.toastService.warning('La cédula no debe de estar vacío', '', {
            tapToDismiss: true,
            timeOut: 4000
          });
          this.disabled = false;
        }
      } else {
        await this.autService.recoveryPassword(this.rememberAccount.controls.username.value);
        this.toastService.success('Se ha enviado un correo para poder reestablecer la contraseña', '', {
          tapToDismiss: true,
          timeOut: 6000
        });
        this.disabled = false;
      }
    } catch (error) {
      this.toastService.error('Hubo problema al reestablecer la contraseña', '', {
        tapToDismiss: true,
        timeOut: 4500
      });
      this.disabled = false;
    }
  }


  // Constructores para formularios
  private RememberFormulario() {
    this.rememberAccount = this.formBuilder.group({
      username:  this.username
    });
  }


  // getters para formularios y variables
  get getRememberForm() {
    return this.rememberAccount;
  }
  get getRememberFormControls() {
    return this.rememberAccount.controls;
  }

}
