import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { environment } from 'src/environments/environment';

// import { ModalAgregarTecnicoComponent } from 'src/app/dashboard/componente/modal-agregar-tecnico/modal-agregar-tecnico.component';
//
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

registerLocaleData(localeEs, 'es');
defineLocale('es', esLocale);


@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    // ModalAgregarTecnicoComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot( { preventDuplicates: true, countDuplicates: true} ),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ['places']
    }),
    // NgMultiSelectDropDownModule.forRoot(),
  ],
  // entryComponents: [
  //     ModalAgregarTecnicoComponent
  // ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
