// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  urlDashboard: 'login?explorer=true',
  newUrlDashboard: 'https://wa.me/593997137810',
  // server: 'https://mig-app.easyfix.ec',
  //server: 'http://localhost:8080',
  server: 'https://app.easyfix.ec',
  // server: 'http://3.3.3.90:8000',
  // server: 'http://192.168.140.183:8000',

  page: 'http://localhost:4200',

  apiKushki: {
    // DEVELOPMENT
    url: 'https://backoffice-uat.kushkipagos.com',
    publicMerchantId: '1000000531436955223715574223250',
    privateMerchantId: '1000000531433310209015574223250',
    // PRODUCTION
    // url: 'https://api.kushkipagos.com',
    // publicMerchantId: '1012311746598446532415663370395',
    // privateMerchantId: '1012311746594270321815663370395',
  },

  apiKey: 'AIzaSyBBZANwRnL2_-it7PIpqHAhk7G3FF4WggU',

  firebase: {
    apiKey: 'AIzaSyDXf4iGXM1KFPSCzpFCiAM9O18zavAOOW4',
    authDomain: 'easyfix-5102b.firebaseapp.com',
    databaseURL: 'https://easyfix-5102b.firebaseio.com',
    projectId: 'easyfix-5102b',
    storageBucket: 'easyfix-5102b.appspot.com',
    messagingSenderId: '36593810840'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
